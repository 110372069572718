.about-area .about-left-image img{
    /* max-height: 50vh;
    max-width: 50%; */
    /* border: 1px solid #ddd; */
    border-radius: 20px;
    text-align: center;
    padding: 5px;
    /* max-height: 300px; */
    width: auto;
    object-fit: contain;
    
}
.about-left-image{
  
    /* margin-left: auto;
  margin-right: auto; */
  /* max-height: 50px; */
  /* width: 50%; */
  /* text-align: center; */
}
.border{
    text-align: center;
    border: 1px solid #dee2e6!important;
}
.about-left-image img{
    border-radius: 20px;
    padding: 10px 10px;
}
.left_img_txt_desc{
    /* border: 1px solid #ddd; */
    /* border-radius: 20px; */
    /* background-color: #0c694233; */
}
.left_img_txt_desc p {
    display: flex;
    justify-content: center;
}
