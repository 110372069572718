#divAddressList{
  padding: 20px 20px;
    width: 100%;
    margin: 20px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    overflow-x: scroll;
  
}
#divAddressList:focus{
  box-shadow: 0 0 5px rgba(0,0,0,0.05);
}
.feature-box {
    /* border: 2px solid #000; */
    display: flex;
  }
 
  
  .feature-box h3 {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .feature-item {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    flex-grow: 0;
    /* Prevents column from growing */
    flex-shrink: 0;
    /* Prevents column from shrinking */
    flex-basis: 30%;
    margin-right: 5px;
    min-width: 225px;
  }
  
  .feature-item.selected {
    background-color: #e0e0e03b;
  }
  
  .feature-item input {
    display: block;
  }
  
  .feature-item label {
    margin-left: 5px;
    cursor: pointer;
  }

.checkmark {
  position: relative;
  top: 5px;
  right: 5px;
  font-size: 16px;
  color: green;
}