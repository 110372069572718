.clsSearchCollapse{
    display: none;
}
li.old{
    text-decoration: line-through;
    color: #ee7600;
}
span.old{
    padding-right: 10px;
    text-decoration: line-through;
    color: #ee7600;
}
.rating__star {
    cursor: pointer;
    /*color: #dabd18b2;*/
    /* color: #FFD700; */
 }
 .rating-product i{
   font-size: 26px !important;
 }
 #pro_sm_desc{
     text-align: left;
 }
 .description-review-bottom .product-description-wrapper p{
     width: 100%;
     font-size: 17px;
 }
 .product-details-content{
    padding: 5px 20px;
 }
 .description-review-bottom .product-anotherinfo-wrapper li{
    font-size: 17px;
 }
 .product-anotherinfo-wrapper td{
    font-size: 17px;
 }
 .product-details-content .pro-details-wish-com a{
    font-size: 15px;
 }
 .read-review .reviews a{
    font-size: 15px;
 }
 .product-details-content .pricing-meta{
    margin-bottom: 10px;
 }
 .product-details-content .pro-details-social-info{
    border: none;
 }
 