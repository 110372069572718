/* .blog-image img{
    height: 300px;
    width:247px; 
   } */
   @media only screen and (min-width: 600px){
    /* For portrait layouts only */
    .blog-post-media .blog-image img{
        z-index: 11;
         height: 500px !important; 
    }
  }
   
   .single-blog{
        /* box-shadow: 0 0 15px 10px rgb(0 0 0 / 6%); */
        box-shadow:0 0 3px 0px rgb(2 2 2 / 5%);
       border-radius:20px;
       background:#fff; 
       z-index:5;
       /* padding: 20px 0px; */
   }
   .single-blog:hover{
    transform:scale(1.01) ;
    /* box-shadow: 1px 0 10px 10px rgb(0 0 0 / 20%); */
    /* border:2px solid #908b8b; */
       border-radius: 10px;
   }
   .new-product-wrapper{
       padding: 20px 0px;
   }
   .single-blog img{
       /* height: 217px;
       width: 100%; */
       /* border-radius: 10px; */
       object-fit: contain !important;
       /* border: 1px solid #ddd; */
    border-radius: 10px;
    padding: 5px;
   }
   .blog-text{
       padding: 2px 2px;
       
       text-align: center;
      
   }
   .blog-text .title{
       padding:4px 4px;
   }
   .spotlight{
       background: no-repeat;
       background-image:url('../assets/images/bg-1.png');
       background-position: center bottom;
       background-size: cover;
       position: relative;
       padding: 100px 0;
       z-index:2;
   }
   
   .heading h2:after {
       content: "";
       background: no-repeat;
       background-image: url(../assets/images/border-bottom.svg);
       width: 107px !important;
       height: 5px !important;
       position:absolute;
       left: 0;
       right: 0;
       margin: auto;
       /* bottom: 0; */
       bottom:-10px;
   }
   .section-title.heading .title{
    color: #9a4f17;
    position: relative;
   }
   .single-blog .blog-text{
       margin-top: 0px;
   }
   
   
   
   
   
   