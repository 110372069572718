.grand-totall .h5, .grand-totall h5 {
    font-size: 14px;
    /* margin: 36px 0 27px; */
    font-weight: 400;
}
.grand-totall .total-shipping {
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    margin: 0 0 5px;
    padding: 11px 0;
 
}