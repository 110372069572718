.bill-pbox{
    padding: 20px;
    /* margin: 5px; */
    margin-top: 5px;
    margin-bottom: 5px;
}
.billing-info{
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    /* width: 70%;
    margin-left: 5px;
    float: right; */
}

.billing-info input,select{
    /* margin-left: 5px; */
    /* margin-right: 10px; */
}
.billing-info-wrap label {
    /* margin-bottom: 10px; */
    font-weight: 600;
}
.your-order-area .your-order-wrap{
    background: #fff;
}


.billing-select {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    /* width: 70%;
    margin-left: 5px;
    float: right; */
}
.billing-select select{
    margin-left: 5px;
}