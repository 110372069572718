.blue-btn{
    background: #00b9ff;
    border-color: #00b9ff;
    /* padding: 13px 21px; */
}
.card{
    border: none;
}
.card-body{
    padding: 15px 0px;
}
.blue-btn:hover{
    background: #00acee;
    border-color: #00acee;
}
.link-style{
    font-weight: 500;
}
.slick-prev:before, .slick-next:before {
    color: black !important;
}
.slick-slide {
    margin: 0px 10px;
}
@media only screen and (max-width: 600px) {
  .slick-next {
        right: 0px !important;
    }
  .slick-prev {
        left: 0px !important;
    }
}