.feat_img img{
     height: 80px;
    width: 80px;
    padding-top: 5px; 
   
}
.category-wrapper .category-inner {
    display: flex;
    width: 180px;
    height: 180px;
    /* border: 1px solid #474747; */
    justify-content: center;
    border-radius: 50%;
    transition: all .3s ease 0s;
    position: relative;
    margin: auto;
  
}

.feature_slider_bg{
    position: relative;
}
.feature_slider_bg:before{
    content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
  opacity: 1;
  background-image: url(../assets/images/banner/bg_feature_slide.png);
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-position: 0% 0;
  background-size: contain;
  text-align: left;
  float: left;
     /* background-attachment: fixed; */
}
.feature_slider_bg .swiper-slide .title{
    color:#c32130 !important;
}
.feat_img{
    /* border-radius: 10px;
    -moz-box-shadow: 10px 10px 50px #ccc;
    -webkit-box-shadow: 10px 10px 50px #ccc;
    box-shadow: 10px 10px 50px #ccc; */
}
.feature_slider_bg .category-wrapper{
    padding-left: 20%;
} 
@media only screen and (max-width: 479px)
{
    .category-wrapper .category-inner {
        width: 130px;
        height: 140px;
    }
}
.category-wrapper .category-inner .category-single-item .title {
    display: block;
    font-family: Raleway,sans-serif;
    font-weight: 500;
    color: #474747;
    margin: 12px 0 0;
    transition: all .3s ease 0s;
}
.feature_img_bg{
    /* background-color: #a3bac5;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    box-shadow: 2px 5px #a3bac55e; */
    /* height: 100vh;
    width: 100%; */
}

