.breadcrumb-area{
     padding-top: 100px;
    padding-bottom: 80px;
    
     /* padding-top: 50%; */
     /* top:50%; */
}
.breadcrumb_box{

/* top: 40%;
position: absolute;
text-align: center; */
}
.breadcrumb_box h1,h4{
  padding-bottom: 10px;
  color: #fff;
}
.breadcrumb_box h4{
  color: #fff;
}

.hero-slide-content a,
.hero-slide-content h4, 
.hero-slide-content h5,
.hero-slide-content h3,
.hero-slide-content h2,
.hero-slide-content h1{
  /* font-size: large; */
  color: #ffffed;
  font-weight: 700;

  /* text-decoration: underline; */
}
h2{
  font-size: 29px !important;
  text-transform: capitalize;
}
/*
.inner-banner{
    filter: blur(8px);
    -webkit-filter: blur(8px);
    height: 100%;

  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
*/
 .banner-bg-text1 {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    /* border: 3px solid #f1f1f1; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
  }
  .top-banner .top-title-div{
    background-color: #f2632470;
    border-radius: 10px;
    color:#fff;
  }