.u-section {
    /* min-height: 826px; */
}
.u-grey{
    color: #111111;
    background-color: #f2f2f2;
}
section{
    position: relative;
    display: block;
}
section.u-grey-5:before{
    color: #111111;
    background-color: #f2f2f2;
}
.u-clearfix:before {
    content: '';
    display: table;
}
.u-section .u-shape {
    width: calc(((100% - 1140px) / 2) + 879px);
    left: auto;
    right: 0;
    background-image: none;
}
.u-palette-base{
    color: #ffffff;
    /* background-color: #3c3f5d; */
}
.u-expanded-height {
    position: absolute !important;
    height: 100% !important;
    top: 0 !important;
}
.u-section .u-shape-2 {
    width: 677px;
    height: 566px;
    background-image: none;
    margin: 195px auto 0 calc(((100% - 1140px) / 2) + 224px);
}
.u-palette-2-base{
    color: #ffffff;
    /* background-color: #ec6843; */
}
.u-radius-25{
    border-radius: 25px !important;
}
.u-shape {
    position: relative;
}
.u-section .u-image-1 {
    width: 675px;
    height: 512px;
    margin: -696px auto 0 calc(((100% - 1140px) / 2));
}
img.u-image {
    overflow: hidden;
}
.u-image {
    color: #111111;
}
.u-radius-25 {
    border-radius: 25px !important;
}
.u-align-left {
    text-align: left;
}
.u-image {
    object-fit: cover;
    display: block;
    vertical-align: middle;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
img {
    border-style: none;
}
.u-section .u-group-1 {
    min-height: 443px;
    width: 596px;
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.15);
    margin: -450px calc(((100% - 1140px) / 2)) 60px auto;
}
.u-white{
    color: #111111;
    background-color: #ffffff;
}
.u-radius-50{
    border-radius: 50px !important;
}
.u-group {
    display: flex;
}
.u-radius-50:not(.u-hover-box) > .u-container-layout {
    border-radius: 50px !important;
}
.u-section-2 .u-container-layout-1 {
    padding: 30px 60px;
}
.u-valign-middle {
    justify-content: center;
}
.u-valign-middle{
    display: flex;
    flex-direction: column;
}
.u-container-layout {
    position: relative;
    flex: 1;
    max-width: 100%;
    transition-duration: inherit;
}
.u-section.u-text-1 {
    margin: 0 auto 0 0;
}
.u-group .u-container-layout > * {
    position: relative;
}
.u-section .u-container-layout-1 {
    padding: 30px 60px;
}
.u-valign-middle {
    justify-content: center;
}
.u-valign-middle {
    display: flex;
    flex-direction: column;
}
.u-container-layout {
    position: relative;
    flex: 1;
    max-width: 100%;
    transition-duration: inherit;
}
.u-section .u-text-1 {
    margin: 0 auto 0 0;
}
.u-group .u-container-layout > * {
    position: relative;
}
.u-text {
    word-wrap: break-word;
    position: relative;
}
.u-valign-middle > * {
    flex-shrink: 0;
}
.u-text-default {
    display: table;
    align-self: flex-start;
}
h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    list-icon-spacing: 0.3;
    list-icon-size: 0.8;
}
.u-section .u-text-2 {
    line-height: 1.8;
    margin: 20px 0 0;
}
.u-group .u-container-layout > * {
    position: relative;
}
p:not(.u-text-variant) {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 1.8;
}
.u-text {
    word-wrap: break-word;
    position: relative;
}
.u-section .u-text-3 {
    margin: 20px 325px 0 0;
}
.u-group .u-container-layout > * {
    position: relative;
}
p:not(.u-text-variant) {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 1.8;
}
a.u-button-style.u-text-body-color, a.u-button-style.u-text-body-color[class*="u-border-"] {
    color: #111111 !important;
}
.u-text .u-btn.u-button-link {
    display: inline;
}
a.u-button-style.u-border-1 {
    border-width: 1px;
}
.u-section .u-btn-1 {
    border-style: none none solid;
    padding: 0;
}
.u-button-style.u-border-black {
    border-color: #000000 !important;
    color: #000000 !important;
    background-color: transparent !important;
}

.u-table-cell .u-btn, .u-text .u-btn {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: baseline;
    white-space: normal;
    text-align: inherit;
}
[class*="u-border-"]:not(.u-shape-svg) {
    stroke: none;
}
.u-text a{
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
}
.u-button-style.u-palette-2-base, .u-button-style.u-palette-2-base[class*="u-border-"] {
    color: #ffffff !important;
    background-color: #ec6843 !important;
}
.u-section-2 .u-btn-2 {
    letter-spacing: 2px;
    font-size: 0.875rem;
    background-image: none;
    text-transform: uppercase;
    font-weight: 500;
    margin: 40px auto 0 0;
    padding: 19px 45px 20px 44px;
}

.u-clearfix:after {
    clear: both;
}

.u-clearfix:after, .u-clearfix:before {
    content: '';
    display: table;
}
.u-button-style.u-border-none{
    border-color: transparent !important;
    border-width: 0;
}
section > * {
    position: relative;
}
@media (max-width: 1199px){
    .u-section .u-shape-1 {
        width: calc(((100% - 940px) / 2) + 700px);
    }
}
@media (max-width: 1199px){
    .u-section .u-shape-2 {
        margin-top: 185px;
        margin-right: calc(((100% - 940px) / 2) + 91px);
        margin-left: auto;
    }
}
@media (max-width: 1199px){
    .u-section .u-image-1 {
        margin-top: -686px;
        margin-left: calc(((100% - 940px) / 2));
    }
}
@media (max-width: 1199px){
    .u-section .u-group-1 {
        margin-right: calc(((100% - 940px) / 2));
    }
}
@media (max-width: 991px){
    .u-section {
        min-height: 923px;
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .u-valign-middle-md {
        justify-content: center;
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .u-valign-middle-md, .u-valign-top-md, .u-valign-bottom-md {
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 991px){
    .u-section .u-shape-1 {
        width: calc(((100% - 720px) / 2) + 500px);
    }
}
@media (max-width: 991px){
    .u-section .u-shape-2 {
        width: 620px;
        height: 601px;
        margin-top: 60px;
        margin-right: calc(((100% - 720px) / 2) + 43px);
    }
}
@media (max-width: 991px){
    .u-section .u-image-1 {
        width: 593px;
        height: 467px;
        margin-top: -721px;
        margin-left: calc(((100% - 720px) / 2));
    }
}
@media (max-width: 991px){
    .u-section .u-group-1 {
        margin-top: -116px;
        margin-right: calc(((100% - 720px) / 2));
        margin-bottom: -60px;
    }
}
@media (max-width: 991px){
    .u-section .u-container-layout-1 {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media (max-width: 767px){
    .u-section {
        min-height: 915px;
    }
}
@media (max-width: 767px){
    .u-section .u-shape-1 {
        width: calc(((100% - 540px) / 2) + 454px);
    }
}
@media (max-width: 767px){
    .u-section .u-shape-2 {
        width: 485px;
        height: 496px;
        margin-top: 164px;
        margin-right: auto;
    }
}
@media (max-width: 767px){
    .u-section .u-image-1 {
        width: 465px;
        height: 388px;
        margin-top: -583px;
        margin-left: calc(((100% - 540px) / 2));
    }
}
@media (max-width: 767px){
    .u-section .u-group-1 {
        width: 468px;
        margin-top: -70px;
        margin-right: calc(((100% - 540px) / 2));
        margin-bottom: -28px;
    }
}
@media (max-width: 767px){
    .u-section .u-container-layout-1 {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media (max-width: 767px){
    .u-section .u-text-3 {
        margin-right: 237px;
    }
}
@media (max-width: 575px){
    .u-section {
        min-height: 836px;
    }
}
@media (max-width: 575px){
    .u-section .u-shape-1 {
        width: calc(((100% - 340px) / 2) + 340px);
    }
}
@media (max-width: 575px){
    .u-section .u-shape-2 {
        width: 300px;
        margin-top: 112px;
        margin-right: calc(((100% - 340px) / 2));
    }
}
@media (max-width: 575px){
    .u-section .u-image-1 {
        width: 305px;
        height: 278px;
        margin-top: -568px;
        margin-left: auto;
    }
}
@media (max-width: 575px){
    .u-section .u-group-1 {
        min-height: 442px;
        width: 305px;
        margin-top: 36px;
        margin-right: auto;
        margin-bottom: 40px;
    }
}
@media (max-width: 575px){
    .u-section .u-container-layout-1 {
        padding-top: 29px;
        padding-bottom: 29px;
    }
}
@media (max-width: 575px){
    .u-section .u-text-3 {
        margin-right: 94px;
    }
}
u-button-style.u-palette-2-base, .u-button-style.u-palette-2-base[class*="u-border-"] {
    color: #ffffff !important;
    background-color: #ec6843 !important;
}
.u-button-style.u-palette-2-base, .u-button-style.u-palette-2-base[class*="u-border-"] {
    color: #ffffff !important;
    background-color: #ec6843 !important;
}
.u-section .u-btn-2 {
    letter-spacing: 2px;
    font-size: 0.875rem;
    background-image: none;
    text-transform: uppercase;
    font-weight: 500;
    margin: 40px auto 0 0;
    padding: 19px 45px 20px 44px;
    text-decoration: none;
}
