#latest_update{
  background: rgb(189,194,232);
background: linear-gradient(175deg, rgba(189,194,232,1) 0%, rgba(230,222,233,1) 100%);
  
}
@media (max-width: 768px){
  .news-container{
    width: 100%;
    /* max-width: 500px;  */
      }
}
@media (min-width: 700px){
  .news-container{
      /* width: 70% !important; */
      width: 300px;
      max-width: 500px; 
      }
}

.news-container {
  position: relative;
  
  /* height: 400px;*/
  /* max-height: 500px;  */
  /* height: 100vh; */
  border: 1px solid #ccc; 
  overflow: auto;
  padding : 0 10px; 
  box-sizing: border-box;
  background: rgb(189,194,232);
background: linear-gradient(175deg, rgba(189,194,232,1) 0%, rgba(230,222,233,1) 100%);
  border-radius: 0px;
  margin-right: 2px;
 
}
.news-item {
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid rgb(189, 189, 189) ;
  transition: color 0.3s ease;
}
.news-item a{
  text-decoration: none;
  color: #083636;
}
.newscontainerhead{
  margin-top: 10px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 40px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
}
.content_container{
  margin-top: 5px;
  display: flex;
}
.news-item a:hover{
  color:#600202;
  transition: 0.2s;
  cursor: pointer;
}
.news-container::-webkit-scrollbar {
width: 15px;
background-color: transparent;

}
.news-container::-webkit-scrollbarr-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 10px;

}
.news-container::-webkit-scrollbar-thumb {
background: #116868; 
border-radius: 10px;


}
.news-container::-webkit-scrollbar-thumb:hover {
background: #083636; 
}
.imagetitle{
margin-top: 10px;
}
.news_div_container{
margin-top: 20px;
}
