.cardsdivashtech .card {
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.16);
  max-width: 290px;
  max-height: 440px;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  box-sizing: border-box;
  transition: border 0.1s ease, transform 0.1s ease;
  overflow: hidden;
}

.cardsdivashtech .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.cardsdivashtech .card-body svg {
  height: 25px;
  color: rgb(26, 52, 82);
}

.cardsdivashtech .card-body .card-svg {
  transition: transform 0.4s ease-in-out;
}

.cardsdivashtech .card:hover .card-svg {
  transform: rotate(-60deg);
}

.cardsdivashtech .card img {
  height: 75px;
  width: 75px;
  margin-top: 25px;
}

.cardsdivashtech .card-text {
  font-size: 20px;
  font-weight: 700;
}

.cardsdivashtech .card:hover {
  border: 2px solid #F27141;
  border-radius: 12px;
}

.cardsdivashtech .card p {
  box-sizing: content-box; 
}

.cardsdivashtech .card p:before,
.cardsdivashtech .card p:after {
  box-sizing: content-box;
}

@media (max-width: 699px) {
  .cardsdivashtech .col-sm-6 {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-right: 5px;
    margin-left: 5px;
  }
  .cardsdivashtech .card {
    overflow: hidden;
    height: 230px;
    max-width: 100%;
  }
  .cardsdivashtech .text-muted {
    display: none;
  }
  .cardsdivashtech .card img {
    height: 50px;
    width: 50px;
  }
  .cardsdivashtech .row {
    margin-right: -5px;
    margin-left: -5px;
  }
}