.recent-blog-wid{
    /* border: 1px solid #ddd;  */
    padding:10px 10px !important;
    
}
.thumb-side img{
    border-radius: 5px;
    margin-bottom: 2px;
    border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}
.sidebar-widget .recent-single-post{
   border-bottom: 1px solid #0063383b;
  margin:0 0 5px !important;
 /* border: 1px solid #ddd; */

}
.left-sidebar{
   
}
