.invoiceimg img{
    height: 50px;
    width: 50px;
}
.invoiceimg .row{
    padding-right: 0px;
}
.invoiceimg .img-container {
    display: flex;
    align-items: right; /* Align items vertically in the center */
}

.invoiceimg .img-container img {
    margin-right: 10px; 
}
.btn-sm {
    width: auto;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    text-align: center;
    
}
@media  (max-width: 600px)
{
.invoicecard .d-flex-mob
{
 display: flex;
}
.invoicecard .d-flex-mob h6{
    width: 50%;
}
.invoicecard .d-flex-mob span{
    float: right;
    width: 50%;
    text-align: right;
}

    
}
/* .float-right{
    float: right;
} */
