.wrapperdivmain .background-container {
    position: relative;
     width: 100%;
     min-height: 100vh;
     background-image: url('https://cdn.pixabay.com/photo/2024/04/05/05/17/business-8676529_1280.jpg');
     background-size: cover; 
     background-position: center;
     display: flex; 
     justify-content: center;
     align-items: center;
     background-attachment: fixed; 
 }
 
 .wrapperdivmain .overlay {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5); 
     z-index: 1;
 }
 
 
 .wrapperdivmain .cards-container {
     position: relative;
     z-index: 2;
     width: 80%;
     margin: auto; 
 }
 
 .wrapperdivmain .card {
     position: relative;
     height: 200px;
     border-radius: 10px;
     overflow: hidden;
     cursor: pointer;
     color: white;
     transition: transform 0.5s ease;
     margin-bottom: 15px;
     background: rgba( 255, 255, 255, 0.25 );
     box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
     backdrop-filter: blur( 3px );
     -webkit-backdrop-filter: blur( 3px );
     border-radius: 10px;
     border: 1px solid rgba( 255, 255, 255, 0.18 );
 }
 
 .wrapperdivmain .card-content {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     font-size: 20px;
     font-weight: bold;
     z-index: 2; 
 }
 
 .wrapperdivmain .hover-title {
     position: absolute;
     top: 20px;
     left: 50%;
     transform: translateX(-50%);
     font-size: 24px;
     font-weight: bold;
     color: white;
     opacity: 0; 
     transition: opacity 0.5s ease; 
     z-index: 3;
 }
 
 .wrapperdivmain .card::before,
 .wrapperdivmain .card::after {
     content: '';
     position: absolute;
     width: 50%;
     height: 100%;
     top: 0;
     background-color: #00000028;
     transition: all 0.5s ease;
     z-index: 1; 
 }
 
 .wrapperdivmain .card::before {
     left: -50%;
 }
 
 .wrapperdivmain .card::after {
     right: -50%;
 }
 
 .wrapperdivmain .card:hover::before {
     transform: translateX(100%); 
 }
 
 .wrapperdivmain .card:hover::after {
     transform: translateX(-100%); 
 }
 
 .wrapperdivmain .card:hover .hover-title {
     opacity: 1;
 }
 
 .wrapperdivmain .card:hover {
     transform: scale(1.01); 
     z-index: 2; 
 }
 .wrapperdivmain .card:hover .card-content {
     opacity: 0; 
 }
 .wrapperdivmain .card-content svg{
     height: 75px;
 }
 .wrapperdivmain .webbsite svg{
     color: rgb(13, 216, 148);
 }
 .wrapperdivmain .sftwr-development svg{
     color: rgb(176, 226, 59);
 }
 .wrapperdivmain .domainn svg{
     color: rgb(59, 207, 226);
 }
 .wrapperdivmain .seoo svg{
     color: yellow;
 }
 .wrapperdivmain .graphicc svg{
     color: orange;
 }
 .wrapperdivmain .logoo svg{
     color: greenyellow;
 }
 .wrapperdivmain .stu-trainingg svg{
     color: rgb(135, 46, 153);
 }
 .wrapperdivmain .bulk-sms svg{
     color: bisque;
 }
 @media (max-width: 768px) {
     .wrapperdivmain .background-container {
         background-attachment: fixed !important;
         background-position: center center;
         background-repeat: no-repeat;
         background-size: cover;
 }
 .wrapperdivmain .card {
 height: 150px;
 margin-bottom: 10px;
 background: rgba( 255, 255, 255, 0.25 );
 box-shadow: 0 4px 16px 0 rgba( 31, 38, 135, 0.37 );
 backdrop-filter: blur( 3px );
 -webkit-backdrop-filter: blur( 3px );
 }
 .wrapperdivmain .overlay {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;;
     background-color: rgba(0, 0, 0, 0.5); 
     z-index: 1;
 }
 .wrapperdivmain .card-content p, .wrapperdivmain  .hover-title{
     font-size: 15px;
 }
 }
 
 
 /* new media query for svg and text */
 @media (max-width: 600px) {
     .wrapperdivmain .card-content svg {
       height: 50px;
       width: 50px;
     }
     
     .wrapperdivmain .card-content p {
       line-height: 1.2;
     }
   }