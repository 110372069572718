@media only screen and (min-width: 600px){
    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        margin: 10px;
        position: relative;
      }
    }
    
    @media only screen and (max-width: 700px){
      .grid {
          display: grid;
          grid-template-columns: repeat(0, 1fr);
          grid-gap: 10px;
          margin: 10px;
          position: relative;
        }
      }
      
      .photo a {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      .photo img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .photo video {
        display: block;
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
      
      
      .photo:nth-child(11n + 1){
        grid-area:span 2/span 2;
      } 
    
       .photo:nth-child(11n + 10) {
        grid-area:3/3/span 2/span 2
      }
      .photo:nth-child(11n + 11){
        grid-column:span 4;
        margin-top:10px;
      }
      
      .photo:nth-child(11n + 1),
      .photo:nth-child(11n + 4),
      .photo:nth-child(11n + 5){
        margin-bottom:10px;
      }
      .orange-bg-2{
    /* background-color: #dbf8fc; */
    background-color: #f0efef5e;
      }
     .clrg_title{
      position: relative;
      bottom: 20%;
      padding-left: 10px;
      color: #fff;
      background-color: #b4b1b140;
      max-height: 25px;
      overflow: hidden;
     } 