.single-contact-info .contact-info-inner{
    min-width: 90px !important;
}
#vehicle1{
    height: 15px;
    width: min-content;
    margin-right: 5px;
}
.sub-title{
    margin-right: 5px;
}
.contact-info-wrap{
padding:40px 20px !important;
}