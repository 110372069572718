#leading_blog a{
    color: #14955f !important;
    font-weight: 700;
    /* text-decoration: underline; */
  }
  .blog-title{
    text-transform: capitalize !important;
   }
   .pro-details-social-info {
    display: flex;
    border-bottom: 1px solid #ebebeb;
    margin-top: 10px;
    padding-bottom: 12px;
}
.pro-details-social-info .social-info a {
  display: inline-block;
  line-height: 30px;
  height: 30px;
  text-align: center;
  color: #474747;
  margin-right: 0;
  white-space: normal;
  text-indent: 0;
  overflow: hidden;
  padding: 0;
  font-size: 18px;
  width: 30px;
}